import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

// Pages
import Home from './Pages/Home';
import Login from './Pages/Login';
import Orders from './Pages/Orders';
import NotFound from './Pages/NotFound';
import Partners from './Pages/Partners';

// Services
import { isAuthenticated } from './Controllers/AuthController';

// Components
import Footer from './Components/Footer';
import Header from './Components/Header';

// Stylesheets
import './App.scss';
import Operators from './Pages/Operators';
import Trips from './Pages/Trips';
import AcknowledgeNotification from './Pages/AcknowledgeNotification';
import Stats from './Pages/Stats';

function PrivateRoute({
  children, isAuthed, isLoading,
}) {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthed ? children : <Navigate to="/login" replace />;
}

function OnlyPublicRoute({
  children, isAuthed, isLoading,
}) {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return !isAuthed ? children : <Navigate to="/home" replace />;
}

function AppRoutes() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthed, setIsAuthed] = useState(false);
  const [session, setSession] = useState({});

  useEffect(() => {
    // Simulate an async auth check
    const authenticate = async () => {
      try {
        // Replace with your actual async auth check
        const authStatus = await isAuthenticated();
        setIsAuthed(authStatus.success);
        setSession(authStatus.data);
      } catch (error) {
        console.error('Authentication check failed:', error);
        setIsAuthed(false);
      } finally {
        setIsLoading(false);
      }
    };

    authenticate();
  }, []);

  return (
    <>
      <Header session={session} isAuthed={isAuthed} />
      <div className="container-custom mt-5">
        <Routes>
          <Route
            exact
            path="/home"
            element={(
              <PrivateRoute isAuthed={isAuthed} isLoading={isLoading}>
                <Home session={session} />
              </PrivateRoute>
                  )}
          />
          <Route
            exact
            path="/login"
            element={(
              <OnlyPublicRoute isAuthed={isAuthed} isLoading={isLoading}>
                <Login setIsAuthed={setIsAuthed} />
              </OnlyPublicRoute>
                  )}
          />
          <Route
            exact
            path="/AcknowledgeNotification/:bookingId/:operatorId"
            element={(
              <OnlyPublicRoute isAuthed={isAuthed} isLoading={isLoading}>
                <AcknowledgeNotification />
              </OnlyPublicRoute>
                  )}
          />
          <Route
            exact
            path="/orders"
            element={(
              <PrivateRoute isAuthed={isAuthed} isLoading={isLoading}>
                <Orders />
              </PrivateRoute>
                  )}
          />
          <Route
            exact
            path="/partners"
            element={(
              <PrivateRoute isAuthed={isAuthed} isLoading={isLoading}>
                <Partners />
              </PrivateRoute>
                  )}
          />
          <Route
            exact
            path="/operators"
            element={(
              <PrivateRoute isAuthed={isAuthed} isLoading={isLoading}>
                <Operators />
              </PrivateRoute>
                  )}
          />
          <Route
            exact
            path="/trips"
            element={(
              <PrivateRoute isAuthed={isAuthed} isLoading={isLoading}>
                <Trips />
              </PrivateRoute>
                  )}
          />
          <Route
            exact
            path="/stats"
            element={(
              <PrivateRoute isAuthed={isAuthed} isLoading={isLoading}>
                <Stats />
              </PrivateRoute>
                  )}
          />
          <Route
            path="/"
            element={(
              <PrivateRoute isAuthed={isAuthed} isLoading={isLoading}>
                <Home session={session} />
              </PrivateRoute>
                  )}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

export default AppRoutes;
