/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import 'react-datetime/css/react-datetime.css';
import {
  Modal,
} from 'react-bootstrap';
import _, { defaults } from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { get } from '../../../Services/API';
import Error from '../../../Components/Error';

import BookingService from '../../../Services/BookingService';
import OrderInputForm from './OrderInputForm';
import OrderInputPreview from './OrderInputPreview';

dayjs.extend(utc);

function OrderInputModal({
  originalOrder = {}, show = false, handleClose, fetchOrders,
}) {
  const defaultOrder = {
    billing: {},
    destination_from: {},
    destination_to: {},
    date_datetime: null,
    return_date_datetime: null,
    partner_booking_reference: '',
    admin_comments: null,
    operator_cancellation_price: 0,
    partner_cancellation_price: 0,
    passengers: {
      amount: 0,
      adults: 0,
      infant_carrier: 0,
      booster_seat: 0,
      convertible_seat: 0,
    },
    send_notification_to_customer: false,
    discount: {
      percentage: 0,
    },
    currency: '',
    payment: {
      paid: 0,
    },
    status: 'Confirmed',
    category: {
      name: '',
      capacity: 0,
      max_luggage: 0,
    },
  };

  if (originalOrder?.payment?.barion_payment_id) {
    // eslint-disable-next-line no-param-reassign
    originalOrder.payment.paid = true;
  }

  const [order, setOrder] = useState(defaults(originalOrder, defaultOrder));
  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState([]);
  const [error, setError] = useState('');
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [paymentTypes] = useState([
    'Cash',
    'Bank Transfer',
    'Card Transfer',
    'Mixed Payment',
    'Transfer from Partner',
  ]);
  const [orderStatuses] = useState([
    'Completed',
    'Confirmed',
    'Cancelled',
    'Request',
  ]);

  const handleSubmit = async (sendNotificationToCustomer) => {
    setLoading(true);

    order.send_notification_to_customer = sendNotificationToCustomer;

    try {
      await BookingService.postBooking(order);
      fetchOrders();
      handleClose();
    } catch (err) {
      setIsPreviewMode(false);
      setError(err.response.data.message);

      if (err.response.data.errors) {
        setValidationErrors(err.response.data.errors);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchPartners = async () => {
    const response = await get('bookings/partners');
    setPartners(response?.data.data?.partners);
  };

  const debouncedFetchPartners = _.debounce(() => {
    fetchPartners();
  }, 500);

  useEffect(() => {
    debouncedFetchPartners();
  }, []);

  return (
    <Modal show={show} onHide={handleClose} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>
          { order._id && (
          <>
            Edit order
            { ' ' }
            { order.booking_reference }
          </>
          ) }
          { !order._id && (
          <>
            New order
          </>
          ) }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Error
          error={error}
          setError={setError}
        />

        { !isPreviewMode
        && (
        <OrderInputForm
          setOriginalOrder={setOrder}
          originalOrder={order}
          validationErrors={validationErrors}
          partners={partners}
          orderStatuses={orderStatuses}
          paymentTypes={paymentTypes}
          setIsPreviewMode={setIsPreviewMode}
        />
        )}

        { isPreviewMode && (
        <OrderInputPreview
          setIsPreviewMode={setIsPreviewMode}
          order={order}
          partners={partners}
          loading={loading}
          handleSubmit={handleSubmit}
        />
        ) }
      </Modal.Body>
    </Modal>
  );
}

export default OrderInputModal;
